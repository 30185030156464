import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../common/info-dialog/info-dialog.component';
import { PasswordInfoDialogComponent } from '../common/password-info-dialog/password-info-dialog.component';
import { PromoCampaignService } from 'src/app/services/promo-campaign.service';
import { AdministratorManagementService } from 'src/app/services/administrator-management.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup = this.buildLoginForm();
  public formSubmitted: boolean = false;
  public invalidAuthenticationData: boolean = false;
  public isSpinnerVisible: boolean = false;

  public get loginControls() {
    return this.loginForm?.controls;
  }

  constructor(
    private formValidation: FormValidationService,
    private formBuilder: UntypedFormBuilder,
    private navigator: NavigatorService,
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private promoCampaignService: PromoCampaignService,
    private adminManagementService: AdministratorManagementService
  ) {
    this.loginForm = this.buildLoginForm();
  }

  public ngOnInit(): void {
    if (this.userDataService.isUserLoggedIn) {
      this.redirectSuccessFulLogin();
    }
  }

  public resetPassword(): void {
    this.navigator.redirectToPasswordResetScreen();
  }

  public async confirm(): Promise<void> {
    //jezeli logowanie nie adminow nie jest mozliwe
    if(this.promoCampaignService.nonAdminLoginLock){
      //sprawdz czy ten email to admin
      const isAdmin = await this.adminManagementService.isEmailAdmin(this.loginControls.email.value);
      console.log(isAdmin);
      if(!isAdmin){
        this.showCannotLogInScoreProcessing();
      } else {
        this.formSubmitted = true;
        if (!this.loginForm.invalid) {
          this.sendLoginRequest();
        }
      }
    } else {
      this.formSubmitted = true;
      if (!this.loginForm.invalid) {
        this.sendLoginRequest();
      }
    }
  }

  private buildLoginForm(): UntypedFormGroup {
    return this.formBuilder.group({
      email: ['', this.formValidation.trimmedRequiredValidator()],
      password: ['', this.formValidation.trimmedRequiredValidator()],
    });
  }

  private async sendLoginRequest(): Promise<void> {
    this.isSpinnerVisible = true;
    const loginResult = await this.userDataService.login(
      this.loginControls.email.value,
      this.loginControls.password.value
    );
    this.isSpinnerVisible = false;
    if (loginResult === true) {
      this.redirectSuccessFulLogin();
    } else {
      this.setInvalidAuthenticationDataInfoVisible();
    }
  }

  private setInvalidAuthenticationDataInfoVisible() : void {
    this.loginControls.email.patchValue('');
    this.loginControls.password.patchValue('');
    this.invalidAuthenticationData = true;
    setTimeout(() => {
      this.invalidAuthenticationData = false;
    }, 5000);
  }

  private redirectSuccessFulLogin() : void {
    if (this.userDataService.isUserPasswordChanged) {
      this.navigator.redirectToLandingPage();
    } else {
      if(this.promoCampaignService.campaignActive){
        this.showCannotRegisterNewAccountDuringFinal();
      } else {
        this.navigator.redirectToInitialDataScreen();
      }
    }
  }

  private showCannotLogInScoreProcessing() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Uwaga',
      message: 'Trwa obliczanie wyników konkursu, w tym momencie logowanie nie jest możliwe. Proszę spróbować później.',
      isErrorInfo: true,
      okFunc: null
    } });
  }

  private showCannotRegisterNewAccountDuringFinal() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Uwaga',
      message: 'Podczas trwania finału akcji promocyjnej nie można zarejestrować nowego konta.',
      isErrorInfo: true,
      okFunc: null
    } });
  }
  
  public showPasswordOrigins(){
    this.dialog.open(PasswordInfoDialogComponent, { disableClose: true, data: {
      isErrorInfo: false,
      okFunc: null
    } });
  }
}
