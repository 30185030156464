import { Injectable } from '@angular/core';
import { MongoService } from './mongo.service';

@Injectable({
  providedIn: 'root'
})
export class PromoCampaignService {

  private _campaignActive: boolean = false;
  private _usersMessageActive: boolean = false;
  private _userMessage: string = "";
  private _nonAdminLoginLock: boolean = false;


  public get campaignActive() {
    return this._campaignActive;
  }

  public set campaignActive(campaignActive:boolean) {
    this._campaignActive = campaignActive;
  }

  public get usersMessageActive() {
    return this._usersMessageActive;
  }

  public set usersMessageActive(usersMessageActive:boolean) {
    this._usersMessageActive = usersMessageActive;
  }

  public get nonAdminLoginLock() {
    return this._nonAdminLoginLock;
  }

  public set nonAdminLoginLock(nonAdminLoginLock:boolean) {
    this._nonAdminLoginLock = nonAdminLoginLock;
  }

  public get userMessage() {
    return this._userMessage;
  }

  public set userMessage(userMessage:string) {
    this._userMessage = userMessage;
  }

  constructor(
    private mongoService: MongoService
  ) { }

  public async initialize() : Promise<void> {
    const promoCampaignSetup = await this.mongoService.getPromoCampaignSettingsData();
    if(promoCampaignSetup){
      this._campaignActive = promoCampaignSetup[0].campaignActive;
      this._usersMessageActive = promoCampaignSetup[0].usersMessageActive;
      this._userMessage = promoCampaignSetup[0].userMessage;
      this._nonAdminLoginLock = promoCampaignSetup[0].nonAdminLoginLock;
    }
  }
}
