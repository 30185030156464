import { Injectable } from '@angular/core';
import { MongoService } from './mongo.service';

@Injectable({
  providedIn: 'root'
})
export class AdministratorManagementService {

  constructor(
    private mongoService: MongoService
  ) { }

  // public async checkIfUserIdAdmin(user: User) : Promise<boolean> {
  //   if (user) {
  //     console.log("checkIfUserIdAdmin user ", user);
  //     const userId = user.uid;
  //     const userAdminData = await this.getAdminData(userId);
  //     console.log("checkIfUserIdAdmin userAdminData ", userAdminData);
  //     return userId === userAdminData?.adminId &&
  //       user.email === userAdminData?.adminEmail;
  //   }
  //   return false;
  // }

  public async getAllAdminsEmailAddresses() : Promise<Array<string>> {
    const adminsData = await this.getAllAdminsData();
    return adminsData.map(d => d.adminEmail);
  }

  public async isEmailAdmin(email:string): Promise<boolean> {
    const adminsData = await this.getAllAdminsData();
      return adminsData.some(item => item.adminEmail === email);
  }

  public async getAdminData(userId: string) : Promise<any> {
    try {
      let data = await this.mongoService.getAdminData(userId);
      if(data?.length === 1){
        return data[0];
      }
      return null;
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  private async getAllAdminsData() : Promise<Array<any>> {
    try {
      let data = await this.mongoService.getAllAdminData();
      if (data?.length > 0) {
        return data;
      }
      return [];
    } catch (e) {
      console.error(e);
      return []
    }
  }
}
